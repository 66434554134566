.usp-bar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;

    @include media-breakpoint-down(lg) {
        &.usp-count {
            &-3 {
                li {
                    animation-name: fade-3;
                    animation-duration: 6s;
                }
            }

            &-2 {
                li {
                    animation-name: fade-2;
                    animation-duration: 4s;
                }
            }
        }
    }

    li {
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 0;
            opacity: 0;
            animation-name: fade-4;
            animation-duration: 8s;
            animation-iteration-count: infinite;

            &:nth-child(1) {
                position: relative;
                animation-delay: 0s;
            }

            &:nth-child(2) {
                animation-delay: 2s;
            }

            &:nth-child(3) {
                animation-delay: 4s;
            }

            &:nth-child(4) {
                animation-delay: 6s;
            }

            &:nth-child(5) {
                animation-delay: 8s;
            }
        }
    }

    &-checkout {
        .footer-usp-bar & {
            &-col-title {
                text-align: center;
            }

            &-col-usps {
                flex-direction: row !important;
            }

            &-usp {
                flex-direction: column;
                justify-content: center;

                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                }

                &-text {
                    text-align: center;

                    @include media-breakpoint-up(lg) {
                        text-align: left;
                    }
                }
            }
        }
    }
}

@keyframes fade-4 {
    0% {
        opacity: 0;
    }

    12.5% {
        opacity: 1;
    }

    25% {
        opacity: 1;
    }

    37.5% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-3 {
    0% {
        opacity: 0;
    }

    16.5% {
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    49.5% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-2 {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
