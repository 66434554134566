@font-face {
    font-family: Roboto;
    src: url("#{$global-font-path}/roboto-regular.woff") format("woff");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url("#{$global-font-path}/roboto-medium.woff") format("woff");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url("#{$global-font-path}/roboto-bold.woff") format("woff");
    font-weight: 700;
    font-display: swap;
}
