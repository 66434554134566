@import "../variables";

.price {
    color: $price-color;
    font-weight: $price-font-weight;
    display: inline-flex;
    flex-flow: wrap;
    align-items: baseline;
    gap: 0 .25rem ;

    >span {
        display: inline-flex;
        flex-flow: inherit;
        align-items: inherit;
        gap: inherit;
    }

    .value {
        white-space: nowrap;
    }

    del {
        color: $strike-price-color;
        font-size: $strike-price-font-size;
        font-weight: $strike-price-font-weight;
        order: $strike-price-order;
    }

    del+.sales {
        color: $reduced-price-color;
    }

    >.strike-through {
        text-decoration: line-through;
        color: $strike-price-color;
        font-size: $strike-price-font-size;
        font-weight: $strike-price-font-weight;
        order: $strike-price-order;
    }

    .tiered {
        color: $gray-700;

        table {
            border-top: 1px solid $border-color;
            margin: 0 auto;

            tr {
                &:nth-child(odd) {
                    background-color: $gray-200;
                }
            }
        }

        td,
        span.price {
            font-size: 0.875rem;
            font-weight: bold;
        }

        td {
            padding: 0.313rem;

            &.quantity {
                font-weight: normal;
                text-align: right;
            }

            &.value {
                text-align: left;
            }
        }

        .table-header {
            font-size: 1.125rem;
            padding: 0.313rem;
        }

        .column-header {
            font-size: 1rem;
            padding: 0.313rem;
            font-weight: normal;
        }

        .column-header.quantity {
            text-align: right;
        }
    }
}
