@import "../utilities/swatch";
@import "product-common";
@import "quickView";
@import "../variables";

$product-tile-padding-top: 2rem;
$product-tile-padding-bottom: 1rem;
$product-tile-padding-x: .75rem;
$product-tile-img-margin-bottom: 1rem;

.product-tiles {
    .ad-wrapper-horizontal {
        display: none;
    }

    .product-grid-vertical & {
        .ad-wrapper-vertical {
            display: none;
        }

        .ad-wrapper-horizontal {
            display: block;
        }
    }
}

.product-tile {
    // scss-docs-start product-tile-css-vars
    --#{$prefix}product-tile-bg-color: #{$product-tile-bg-color};

    // scss-docs-end product-tile-css-vars

    background-color: var(--#{$prefix}product-tile-bg-color);
    border: $product-tile-border-width solid $product-tile-border-color;
    border-radius: $product-tile-border-radius;
    display: block;
    padding: $product-tile-padding-top $product-tile-padding-x $product-tile-padding-bottom;
    margin: 0;
    text-align: left;
    transition: .15s;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    box-shadow: var(--#{$prefix}box-shadow);

    @media (hover: $media-rule-hover) {
        &:hover {
            border-color: $product-tile-border-color-hover;
            transform: translateY(-1px);
            box-shadow: var(--#{$prefix}box-shadow-lg);
        }
    }

    &-row {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    a {
        text-decoration: none;
    }

    .pos-1 {
        position: relative;
    }

    .pos-2 {
        margin-top: auto;
    }

    .color-swatches {
        min-height: 2.25em;

        .product-tile-color-label {
            cursor: pointer;
            font-size: 1em;

            @include media-breakpoint-down(md) {
                font-size: 0.9375em;
            }

            @include media-breakpoint-down(sm) {
                font-size: 0.8125em;
            }
        }
    }

    .price {
        font-size: $product-tile-price-font-size;
        line-height: 1;
        padding-top: 0.5rem;
    }

    &-product-name {
        font-size: $product-tile-name-font-size;

        .link {
            color: $product-tile-name-color;
        }
    }

    &-ratings {
        > * {
            pointer-events: none;
        }

        .standalone-bottomline {
            padding-top: 0.375rem;
        }
    }

    .image-container {
        a {
            display: block;
            padding-bottom: $product-tile-img-margin-bottom;
        }
    }

    .product-short-description,
    .product-availability-eta {
        display: none;
    }

    .wishlist-tile,
    .remove-from-wishlist {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        color: $product-tile-wishlist-icon-color;
    }

    .badge-group-wrapper {
        position: absolute;
        left: $product-tile-padding-x;
        right: 2.5rem;
        top: $product-tile-padding-x;
        min-height: 1.25rem;
        z-index: 1;
    }

    .product-tile-efficiency-label {
        position: absolute;
        bottom: 100%;
        right: 0;
        margin-bottom: $product-tile-img-margin-bottom;
        pointer-events: none;
    }
}

.product-grid-vertical {
    .product-tile {
        @include media-breakpoint-up(md) {
            padding-bottom: 35px;
        }

        .image-container a {
            @include media-breakpoint-up(md) {
                padding: 0;
            }
        }

        .price {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        .product-short-description {
            @include media-breakpoint-up(md) {
                display: block;
                font-size: $font-size-xs;
                margin-top: 0.75rem;
            }
        }

        .product-tile-efficiency-label {
            @include media-breakpoint-up(md) {
                position: static;
                bottom: auto;
                right: auto;
            }
        }

        .product-tile-row {
            @include media-breakpoint-up(md) {
                display: grid;
                gap: 50px;
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include media-breakpoint-up(xxl) {
                grid-template-columns: 1fr 2fr 1fr;
            }

            @include media-breakpoint-up(xxxl) {
                grid-template-columns: 1fr 3.5fr 1fr;
            }
        }

        .pos-2 {
            @include media-breakpoint-up(md) {
                display: flex;
                align-self: center;
                flex-direction: column;
                text-align: right;
                margin-top: 0;
            }
        }

        .pos-1 {
            @include media-breakpoint-up(md) {
                align-self: center;
            }
        }
    }

    .product-tile-placeholder {
        .pos-2 {
            align-items: end;
        }
    }
}

#choose-bonus-product-modal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $border-color;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}
