.nav-tabs {
    &-radio {
        border-bottom: 0;
        gap: $nav-tabs-radio-gap;

        .nav-item {
            flex: 1 0 calc(50% - ($nav-tabs-radio-gap / 2)) !important;
        }

        .nav-link {
            border-radius: var(--#{$prefix}border-radius);
            border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
            position: relative;
            padding: $nav-tabs-radio-padding-y $nav-tabs-radio-padding-end $nav-tabs-radio-padding-y $nav-tabs-radio-padding-start;
            transition: none;
            font-size: $font-size-sm;

            &::before {
                position: absolute;
                left: $nav-tabs-radio-before-left;
                top: 50%;
                transform: translateY(-50%);
                content:'';
                width: $form-check-input-width;
                height: $form-check-input-width;
                border-radius: 50%;
                background-color: $white;
                border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
            }

            &:hover {
                background-color: $form-check-btn-active-bg-color!important;
                border-color: $form-check-btn-active-bg-color;
            }

            &.active {
                background-color: $form-check-btn-active-bg-color!important;
                border-color: $form-check-input-checked-bg-color;

                &::before {
                    border: $form-check-input-radio-border-width-pseudo solid $form-check-input-checked-bg-color;
                }
            }
        }
    }
}
