@import "drilldown-to-mega-menu";
@import "usp-bar";
@import "toastMessage";

$header-top-z-index: $header-main-z-index + 1;
$header-banner-padding: 0.3125em;

.header {
    &--main {
        min-height: $header-main-md-height;
        position: relative;
        background-color: $header-main-bg-color;

        @include media-breakpoint-up(md) {
            position: sticky;
            top: 0;
            z-index: $header-main-z-index;
        }

        @include media-breakpoint-up(lg) {
            min-height: $header-main-lg-height;
        }

        @include media-breakpoint-up(xxl) {
            min-height: $header-main-xxl-height;
        }

        &-menu-col {
            @include media-breakpoint-up(xxl) {
                min-width: 100%;
            }
        }

        &-logo-col {
            width: fit-content;
            flex: 1;

            @include media-breakpoint-up(lg) {
                flex: 0 1 auto;
            }
        }

        &-search-col {
            flex: 1 0 100%;

            @include media-breakpoint-up(lg) {
                flex: 1 0 20%;
            }

            @include media-breakpoint-up(xl) {
                flex: 1 0 25%;
            }
        }

        &-actions {
            &-col {
                @include media-breakpoint-up(md) {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: 1fr;
                    grid-gap: 0 20px;
                }
            }

            &-link {
                text-decoration: none;
                color: $header-main-action-icon-color;
                display: flex;
                align-items: center;
                height: $header-main-search-and-action-height;
                padding: 0 $header-main-action-padding-x;
                border: $header-main-action-link-border-width solid transparent;
                border-radius: $header-main-action-link-border-radius;
                position: relative;

                svg {
                    fill: $header-main-action-icon-color;
                }

                @media (hover: $media-rule-hover) {
                    &:hover {
                        background-color: $header-main-action-link-bg-color-hover;
                        color: $header-main-action-icon-color-hover;

                        svg {
                            fill: $header-main-action-icon-color-hover;
                        }
                    }
                }
            }
        }

        &-sticky {
            position: sticky;
            top: 0;
            z-index: $header-main-z-index;
        }
    }

    &--no-menu {
        position: relative;
        background-color: $header-no-menu-bg-color;
    }

    &-logo {
        display: block;
        width: 100%;
        max-width: $header-logo-max-width;
        height: 100%;
        max-height: $header-logo-max-height;

        @include media-breakpoint-up(xxl) {
            max-width: $header-logo-xxl-max-width;
        }
    }

    &-banner {
        background-color: $header-banner-bg-color;
        text-align: center;
        color: $header-banner-color;

        .close-button {
            width: 1.5em + $header-banner-padding * 2;

            .close {
                opacity: 1;
                color: $white;
                width: 100%;
                height: 100%;
                background-color: $primary;
            }
        }

        .content {
            margin-right: 1.5em;
            padding-top: $header-banner-padding;
            padding-bottom: $header-banner-padding;
        }
    }

    &-usp-bar {
        color: $header-usp-bar-color;
        background-color: $header-usp-bar-bg-color;

        .page:not([data-action="Home-Show"]) & {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}

a.normal {
    color: $primary;
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

.header-top-bar {
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: $header-top-bar-bg-color;
    border-bottom: 2px solid $header-top-bar-border-color;

    .dropdown-menu {
        z-index: $header-top-bar-language-selector-z-index;
    }

    .country-selector-btn {
        color: $header-top-bar-color;

        &:active {
            color: $header-top-bar-color;
        }

        &:hover {
            color: $header-top-bar-link-color-hover;
        }

        .page[data-action="Order-Confirm"] & {
            display: none !important;
        }
    }

    .dropdown-country-selector {
        min-width: auto;
    }

    .header-top-bar-link {
        color: $header-top-bar-color;
        text-decoration: none;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $header-top-bar-link-color-hover;
        }
    }
}

.user {
    position: relative;

    .popover {
        --#{$prefix}popover-bg: #{$white};

        position: absolute;
        display: none;
        padding: 5px 0;
        top: 100%;
        right: 0;
        min-width: 200px;
        font-size: inherit;

        &.show {
            display: block
        }

        .nav-item {
            &:hover {
                background-color: $primary-100;
            }

            a {
                color: $black;
                font-size: $font-size-sm;
                padding: 10px 0;
            }
        }
    }
}

.feedback-element {
    width: 100%;
    position: absolute;
    bottom: -4px;
    transform: translateY(100%);

    &-wrapper {
        z-index: $header-feedback-element-z-index;
        position: sticky;
        top: 0;

        @include media-breakpoint-up(md) {
            top: $header-main-md-height;
        }

        @include media-breakpoint-up(lg) {
            top: $header-main-lg-height;
        }

        @include media-breakpoint-up(xxl) {
            top: $header-main-xxl-height;
        }
    }

    &-wrapper-header-no-menu {
        z-index: $header-feedback-element-z-index;
        position: sticky;
        top: 0;
    }
}

.toast-message {
    @include toast-message;
}

.toast-alert {
    @include toast-alert;
}
