.product-availability {
    font-size: $product-availability-font-size;

    &-message {
        text-transform: uppercase;
        font-weight: $product-availability-message-font-weight;

        &::before{
            content: "";
            flex-shrink: 0;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 2px;
            background-color: $product-availability-default-color;
        }
    }

    .product-detail-cta-box & {
        .product-availability-eta {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}

@if $product-availability-message-colored {
    @each $status, $value in $availability-colors {
        .product-availability-message-#{$status} {
            color: $value;
        }
    }
}

@each $status, $value in $availability-colors {
    .product-availability-message-#{$status}::before {
        background-color: $value;
    }
}
