@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.item-attributes {
    vertical-align: top;
    padding-left: 0;
}

.line-item-attributes,
.line-item-option {
    font-size: 0.75rem;
    margin: 0;
}

.line-item-name {
    color: $line-item-name-color;
    font-size: $line-item-name-font-size;
}

.line-item-price-quantity-info {
    margin-top: 0.625em;
    border-top: 1px solid $border-color;
}

.product-line-item-image {
    height: $line-item-image-height;
    width: $line-item-image-width;
    margin-right: 1rem;
    flex-grow: 0;
    flex-shrink: 0;

    img.product-image {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
        max-height: $line-item-image-width;
    }
}

.non-adjusted-price {
    display: none;
}

.line-item-promo {
    color: $success;
    font-size: 0.813em;
}

.bundled-line-item + .bundled-line-item {
    margin-top: 0.625em;
}

.bundle-includes {
    font-size: 0.813em;
    margin-bottom: 0.625em;
}

.line-item-divider {
    margin: 0.625em -1.225em 0.625em -1.325em;
}

.product-edit {
    margin-top: auto;

    a {
        font-size: 0.813em;
    }
}
