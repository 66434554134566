@import 'swiper/swiper-bundle';

.swiper {
    display: flex;

    &-container {
        position:relative;
    }

    &-wrapper {
        height: auto;
    }  

    &:not(.swiper-initialized) {
        .swiper-button-prev,
        .swiper-button-next {
            opacity: 0.35;
            pointer-events: auto;
        }
    }

    .swiper-pagination-horizontal {
        bottom: 0;

        .swiper-pagination-bullet {
            width: 25px;
            height: 4px;
            border-radius: 0;
            margin: 0 5px;
            background: $swiper-pagination-inactive-bullet-color;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: $swiper-pagination-active-bullet-color;
            }
        }
    }

    &-button-prev,
    &-button-next {
        height: 40px;
        width: 40px;
        border-radius: 40px;
        background-color: $swiper-navigation-buttons-bg-color;
        box-shadow: 0 3px 20px 0 $swiper-navigation-button-box-shadow-color;
        user-select: none;

        svg {
            height: 24px;
            width: 24px;
            fill: $swiper-navigation-buttons-svg-color;
        }

        &::after {
            display: none;
        }

        &.swiper-button-disabled {
            pointer-events: auto;
        }
    }

    &-arrows-outside,
    .product-carousel & {
        position: static;

        .swiper-button-next {
            right: 0;
    
            @include media-breakpoint-up(md) {
                right: -20px;
            }
    
            @include media-breakpoint-up(xxxl) {
                right: -3rem;
            }
        }
        
        .swiper-button-prev {
            left: 0;
            
            @include media-breakpoint-up(md) {
                left: -20px;
            }
    
            @include media-breakpoint-up(xxxl) {
                left: -3rem;
            }
        }
    }

    &-arrows-edge {
        position: static;

        .swiper-button-next {
            right: 0;
    
            @include media-breakpoint-up(md) {
                right: -20px;
            }
        }
        
        .swiper-button-prev {
            left: 0;
            
            @include media-breakpoint-up(md) {
                left: -20px;
            }
        }
    }

    .product-carousel & {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        margin-bottom: 0;
    }
}
