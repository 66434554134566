.table-orders {
  &-wrapper {
    --#{$prefix}table-bg: #{$table-bg};

    @include media-breakpoint-up(lg) {
      border-radius: $border-radius;
      box-shadow: (var(--#{$prefix}box-shadow));
      background-color: var(--#{$prefix}table-bg);
    }
  }

  thead {
    display: none;

    @include media-breakpoint-up(lg) {
      display: table-header-group;
    }
  }

  tr {
    display: block;
    background-color: var(--#{$prefix}table-bg);
    box-shadow: (var(--#{$prefix}box-shadow));
    border-radius: $border-radius;

    @include media-breakpoint-up(lg) {
      display: table-row;
      box-shadow: none;
    }
  }

  &-number {
    font-weight: bold;

    @include media-breakpoint-up(lg) {
      font-weight: normal;
    }
  }
}
