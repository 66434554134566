:root,
[data-bs-theme="light"] {
  --#{$prefix}primary-200: #{$primary-200};
  --#{$prefix}primary-400: #{$primary-400};
  --#{$prefix}gray-100: #{$gray-100};
}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
    .card {
      --#{$prefix}card-bg: #{$card-bg-dark};
    }
    
    .product-tile {
      --#{$prefix}product-tile-bg-color: #{$product-tile-bg-color-dark};
    }

    .product-detail {
      --#{$prefix}product-detail-bg-color: #{$product-detail-bg-color-dark};
      --#{$prefix}product-detail-cta-box-bg-color: #{$product-detail-cta-box-bg-color-dark};
    }
  }
}
