h1,
.h1 {
    font-size: $h1-font-size-sm;

    @include media-breakpoint-up(md) {
        font-size: $h1-font-size;
    }
}

h2,
.h2 {
    font-size: $h2-font-size-sm;

    @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
    }
}

h3,
.h3 {
    font-size: $h3-font-size-sm;

    @include media-breakpoint-up(md) {
        font-size: $h3-font-size;
    }
}

h4,
.h4 {
    font-size: $h4-font-size-sm;

    @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
    }
}

ol,
ul {
    padding-left: 1.25rem;
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}
