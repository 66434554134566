.navbar {
  &-nav {
    width: 100%;

    @include media-breakpoint-up(lg) {
      border-bottom: $navbar-border-width solid $border-color;
    }
  }

  .nav-link {
    border-left: $navbar-border-width solid transparent;

    @include media-breakpoint-up(lg) {
      position: relative;
      font-size: $navbar-expand-font-size;
      border-left: none;
    }

    &:hover,
    &.active {
      background-color: $nav-link-background-color-active;
      border-color: $nav-link-border-color-active;

      @include media-breakpoint-up(lg) {
        background: none;

        &::after {
          content: "";
          display: block;
          width: 100%;
          left: 0;
          bottom: -$navbar-border-width;
          height: $navbar-border-width;
          background-color: $nav-link-border-color-active;
          position: absolute;
        }
      }
    }

    &.active {
      @include media-breakpoint-up(lg) {
        font-weight: $nav-link-font-weight-active;
      }
    }
  }
}
