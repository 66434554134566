.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.hide-shipping-discount,
.hide-order-discount {
    display: none !important;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-message {
    &-text:last-child {
        margin-bottom: 0;
    }
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}
