@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "product-card";

.minicart {
    position: relative;

    .cart {
        padding: 1.25rem;
        background-color: $minicart-bg-color;
        border-radius: $minicart-border-radius;
    }

    .product-summary {
        max-height: 550px;
        overflow: hidden auto;
    }

    .card-body {
        padding: 0;
    }

    .quantity {
        width: 100%;
    }

    .popover {
        display: none;
        position: absolute;
        top: 100%;
        left: auto;
        right: 0;
        max-width: 430px;
        min-width: 430px;
        max-height: 730px;
        font-size: inherit;

        &.show {
            display: block;
        }
    }

    .minicart-link {
        @include media-breakpoint-up(md) {
            border-color: $minicart-total-border-color;
        }
    }
    
    .sub-total-label {
        color: $minicart-subtotal-color;
    }
}
