.border {
  &-body-bg {
    --#{$prefix}border-color: #{$body-bg};
  }

  &-gray-200 {
    border-color: $gray-200 !important;
  }

  &-hover {
    @media (hover: $media-rule-hover) {
      border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) transparent !important;

        &:hover {
            border-color: var(--#{$prefix}border-color) !important;
        }
    }
  }
}
