.material-icon-wrapper {
    &-default {
        > svg {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &-small {
        > svg {
            width: 20px !important;
            height: 20px !important;
        }
    }

    &-x-small {
        > svg {
            width: 16px !important;
            height: 16px !important;
        }
    }

    &-xx-small {
        > svg {
            width: 14px !important;
            height: 14px !important;
        }
    }
}