@import "collapsibleItem";

footer {
    background-color: $footer-bg-color;
    color: $footer-color;

    .subscribe-wrapper {
        border-bottom: 2px solid $footer-subscribe-section-border;

        .newsletter-form-wrapper {
            width: 100%;
            max-width: 520px;
        }

        .alert {
            z-index: 9999;
        }
    }

    .footer-icons-wrapper {
        .footer-thirdparty-icons-label {
            min-width: 100px;
        }

        .footer-thirdparty-provider-icons img {
            background: $footer-thirdparty-provider-icons-bg;
            border-radius: $footer-thirdparty-provider-icons-border-radius;
        }
    }

    .footer-link {
        color: $footer-links-color;
        text-decoration: none;

        &:hover {
            color: $footer-links-color-hover;
        }
    }

    .footer-pill-link {
        font-size: $footer-pill-font-size;
        color: $footer-pill-link-color;
        background-color: $footer-pill-link-bg-color;
        text-decoration: none;
        padding: 4px 10px;
        border-radius: $footer-pill-border-radius;

        &:hover {
            color: $footer-pill-link-color-hover;
        }
    }

    .footer-copyright-label {
        color: $footer-copyright-label-color;
    }

    .footer-copyright-logo {
        width: 119px;

        @include media-breakpoint-up(md) {
            width: 212px;
        }
    }

    .recaptcha-info {
        .footer-link {
            text-decoration: underline;
        }
    }
}

.footer-usp-bar {
    color: $footer-usp-bar-color;
    background-color: $footer-usp-bar-bg-color;
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}
