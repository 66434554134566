.swatch {
    &-img {
        width: $swatch-width;

        &-overlay {
            position: absolute;
            inset: 0;
            display: block;
            z-index: 2;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &-legacy {
        width: $swatch-width;
        height: $swatch-width;
        display: inline-block;
        background-size: cover;
    
        &.disabled {
            opacity: 0.2;
        }
    
        &.selected {
            border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
        }
    }
}
