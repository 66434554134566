.video-element-thumbnail {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.video-element-iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.video-element-privacy-agreement {
    background: rgba($black, 0.7);
    color: $white;
    z-index: 2;
    bottom: 0;
    left: 0;
}
