.wishlist-tile {
    >svg {
        .icon-path-outter {
            fill: currentcolor;
        }
    }

    &.added {
        >svg {
            .icon-path-inner {
                fill: currentcolor;
            }
        }
    }
}
