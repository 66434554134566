@each $color, $value in $theme-colors {
    .svg-wrapper-#{$color} {
        line-height: 1;

        svg {
            fill: $value !important;
        }
    }
}

@each $color, $value in $theme-colors-800 {
    .svg-wrapper-#{$color} {
        line-height: 1;

        svg {
            fill: $value !important;
        }
    }
}

.svg-wrapper-inherit svg {
    fill: currentcolor !important;
}

.bg {
    &-gray-100 {
        background-color: $gray-100 !important;
    }

    &-primary-100 {
        background-color: $primary-100 !important;
    }

    &-dropdown {
        background-color: var(--#{$prefix}dropdown-bg) !important;
    }
    
    &-card {
        background-color: var(--#{$prefix}card-bg) !important;
    }

    &-body {
        background-color: var(--#{$prefix}body-bg) !important;
    }

    &-xmas-red {
        background-color: $red-700 !important;
    }
}
