.badge {
    text-transform: $badge-text-transform;
    background-color: $badge-bg-color;

    &-header-quantity {
        position: absolute;
        top: -0.5rem;
        left: 1.5rem;
        height: 1.125rem;
        width: 1.125rem;
        line-height: 1.125rem;
        border-radius: var(--#{$prefix}border-radius-pill);
        font-size: $font-size-xs;
        font-weight: normal;
        padding: 0;
        text-align: center;
    }
}

@each $badge, $colors in $badge-custom-colors {
    .badge-#{$badge} {
        background-color: nth($colors, 1);
        color: nth($colors, 2);

        .badge-#{$badge}-suffix {
            color: nth($colors, 3);
        }
    }
}
