$ddtmm-drilldown-overlay-z-index: $ddtmm-drilldown-z-index - 1;

.drilldown-to-mega-menu {
    --mega-menu-height: #{$ddtmm-mega-menu-height};
    --mega-menu-banner-width: #{$ddtmm-mega-menu-banner-width};

    .ddtmm-menu {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    @include media-breakpoint-down(xxl) {
        .ddtmm-site-nav-overlay {
            position: fixed;
            inset: 0;
            z-index: $ddtmm-drilldown-overlay-z-index;
            background: $ddtmm-drilldown-overlay-bg-color;
            opacity: 0.2;
        }

        .ddtmm-site-nav-header {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: $ddtmm-drilldown-header-height;
            color: $ddtmm-drilldown-header-color;
            background: $ddtmm-drilldown-header-bg-color;
            padding: $ddtmm-drilldown-li-padding-y $ddtmm-drilldown-li-padding-x;

            &-x {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 1rem;

                svg {
                    fill: $ddtmm-drilldown-header-color;
                    pointer-events: none;
                    width: 30px;
                    height: 30px;
                }
            }

            img {
                width: 100%;
                max-width: $ddtmm-drilldown-logo-width;
                height: $ddtmm-drilldown-logo-height;
            }
        }

        .ddtmm-site-nav {
            z-index: $ddtmm-drilldown-z-index;
            font-size: $ddtmm-drilldown-font-size;
            padding: $ddtmm-drilldown-header-height 0 0;
            position: fixed;
            width: 85vw;
            max-width: 365px;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: hidden auto;
            transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
            transform: translateX(-100%);
            background: $ddtmm-drilldown-bg-color;

            &.active {
                transform: translateX(0);
            }

            .ddtmm-menu-mobile-wrapper {
                @include media-breakpoint-down(xxl) {
                    overflow: hidden;
                    min-height: 100%;
                    padding-bottom: 50px;
                }
            }

            .ddtmm-menu {
                top: 0;
                width: 100%;
                transform: translateX(0);
                transition: transform 0.3s ease-in-out;
            }

            .ddtmm-pushed {
                transform: translateX(-100%);

                .ddtmm-pushed {
                    transform: translateX(0);
                }
            }

            .ddtmm-menu-item {
                width: 100%;

                &--no-link {
                    color: $ddtmm-drilldown-li-color;
                    padding: $ddtmm-drilldown-li-padding-y $ddtmm-drilldown-li-padding-x;
                }

                > span {
                    padding: $ddtmm-drilldown-li-padding-y $ddtmm-drilldown-li-padding-x;
                    display: block;
                }

                a {
                    padding: $ddtmm-drilldown-li-padding-y $ddtmm-drilldown-li-padding-x;
                    display: block;
                    color: $ddtmm-drilldown-li-color;
                    text-decoration: none;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    
                    &.dtmm-menu-item-highlight {
                        font-weight: $ddtmm-font-weight-bold;
                    }

                    &:focus {
                        background: $ddtmm-drilldown-li-bg-color-active;
                    }
                }

                .ddtmm-sub-menu-trigger,
                .ddtmm-sub-menu-trigger-back {
                    position: relative;

                    &::after {
                        color: $ddtmm-drilldown-li-color;
                        font-size: 1.5rem;
                        line-height: 1;
                        position: absolute;
                        bottom: $ddtmm-drilldown-li-padding-y + 2px;
                    }
                }

                .ddtmm-sub-menu-trigger::after {
                    content: '›';
                    right: $ddtmm-drilldown-li-padding-x;
                }

                .ddtmm-sub-menu-trigger-back {
                    padding-left: 2 * $ddtmm-drilldown-li-padding-x;


                    &::after {
                        content: '‹';
                        left: $ddtmm-drilldown-li-padding-x;
                    }
                }
            }

            .ddtmm-sub-menu {
                position: absolute;
                opacity: 0;
                transform: translateX(100%);
                transition: opacity 0.4s, transform 0.3s ease-in-out;

                &.active {
                    opacity: 1;
                    background-color: $ddtmm-drilldown-bg-color;
                    z-index: 2;
                }
            }
        }

        hr,
        .hr {
            margin: 0;
        }
    }

    @include media-breakpoint-up(xxl) {
        position: relative;

        .ddtmm-site-nav.active {
            display: block;
        }

        @media (hover: $media-rule-hover) {
            &:hover .ddtmm-site-nav {
                display: block;
            }
        }

        .ddtmm-site-nav-header {
            display: none;
        }

        .ddtmm-site-nav {
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            z-index: $ddtmm-mega-menu-z-index;
        }

        .ddtmm-master-menu {
            position: relative;
            z-index: 1;
            background: $ddtmm-drilldown-bg-color;
            margin: 10px 0 0;
            font-size: $ddtmm-mega-menu-font-size;

            &::before {
                content: '';
                position: absolute;
                inset: 0;
                z-index: 0;
                box-shadow: rgba(0 0 0 / 13%) 0 7px 15px, rgba(0 0 0 / 5%) 0 0 3px;
            }

            > .ddtmm-menu-item {
                a,
                span {
                    display: block;
                    text-decoration: none;
                    position: relative;
                    padding: 12px $ddtmm-mega-menu-li-padding;
                    color: $ddtmm-mega-menu-li-color;
                }

                &:hover > a,
                &:hover > span {
                    color: $ddtmm-mega-menu-li-color-active;
                    background: $ddtmm-mega-menu-bg-color;

                    @media (hover: $media-rule-hover) {
                        transition-delay: $ddtmm-mega-menu-hover-display-delay;
                    }
                }

                @media (hover: $media-rule-none) {
                    a.active {
                        color: $ddtmm-mega-menu-li-color-active;
                        background: $ddtmm-mega-menu-bg-color;
                    }
                }

                &.ddtmm-has-sub-menu {
                    > a {
                        padding-right: 2 * $ddtmm-mega-menu-li-padding;

                        &::after {
                            content: '›';
                            position: absolute;
                            right: $ddtmm-mega-menu-li-padding;
                            color: $ddtmm-mega-menu-li-color;
                            font-size: 1.5rem;
                            line-height: 1;
                        }
                    }
                }
            }
        }

        @media (hover: $media-rule-none) {
            .ddtmm-master-menu > .ddtmm-has-sub-menu > a.active ~ .ddtmm-mega-menu {
                visibility: unset;
            }
        }

        .ddtmm-master-menu > .ddtmm-has-sub-menu:hover .ddtmm-mega-menu {
            visibility: unset;

            @media (hover: $media-rule-hover) {
                transition-delay: $ddtmm-mega-menu-hover-display-delay;
            }
        }

        .ddtmm-mega-menu {
            display: flex;
            visibility: hidden;
            flex-flow: column wrap;
            place-content: flex-start;
            align-items: flex-start;
            position: absolute;
            top: 0;
            height: 100%;
            left: 100%;
            width: calc(4 * 100% - var(--mega-menu-banner-width));
            background: $ddtmm-mega-menu-bg-color;
            padding: 0 $ddtmm-mega-menu-li-padding $ddtmm-mega-menu-li-padding;

            &::before {
                content: '';
                position: absolute;
                inset: 0;
                z-index: -1;
                box-shadow: rgba(0 0 0 / 13%) 0 7px 15px, rgba(0 0 0 / 5%) 0 0 3px;
            }

            > .ddtmm-menu-item {
                flex-direction: column;
                align-items: stretch;
                padding: $ddtmm-mega-menu-li-padding $ddtmm-mega-menu-li-padding 0;
                width: calc(100% / 5);

                > a,
                > a > span,
                > span {
                    font-weight: $ddtmm-font-weight-bold;
                    color: $ddtmm-mega-menu-subcategory-color;
                }

                a,
                span {
                    padding: 0;
                    margin-bottom: 5px;
                    display: inline-block;
                }

                a:hover {
                    color: $ddtmm-mega-menu-li-color-active;

                    > span {
                        color: $ddtmm-mega-menu-li-color-active;
                    }
                }
            }

            > .ddtmm-has-sub-menu {
                .ddtmm-sub-menu a {
                    font-weight: normal;
                }

                .ddtmm-has-sub-menu .ddtmm-sub-menu {
                    display: none;
                }
            }
        }
    }

    .ddtmm-mega-menu-ad {
        visibility: hidden;
        display: flex;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        text-align: right;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        a {
            padding: 0 !important;
            display: flex !important;
            position: absolute !important;
            inset: 0;

            > div {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                background-size: cover;
                background-position: center;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .ddtmm-master-menu > li:hover .ddtmm-mega-menu-ad {
            visibility: unset;

            @media (hover: $media-rule-hover) {
                transition-delay: $ddtmm-mega-menu-hover-display-delay;
            }
        }
    }

    &--flat {
        @include media-breakpoint-up(xxl) {
            .ddtmm-site-nav {
                display: block;
                position: relative;
                left: auto;
                right: auto;
            }

            .ddtmm-master-menu {
                display: flex;
                background: transparent;
                margin: 0;

                &::before {
                    content: normal;
                }

                > .ddtmm-menu-item {
                    flex: 1 1 auto;

                    &.ddtmm-has-sub-menu {
                        > a,
                        > span {
                            padding: $ddtmm-mega-menu-li-first-level-padding;

                            &::after {
                                content: normal;
                            }
                        }

                        > span {
                            cursor: default;
                        }
                    }

                    > a,
                    > span {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        color: $white;
                        padding: $ddtmm-mega-menu-li-first-level-padding;

                        &.dtmm-menu-item-highlight {
                            color: $ddtmm-mega-menu-li-first-level-color-highlight;
                            font-weight: $ddtmm-font-weight-bold;
                        }
                    }
                }
            }

            .ddtmm-mega-menu {
                top: 100%;
                height: var(--mega-menu-height);
                left: 0;
                right: 0;
                padding-left: 0;
                padding-right: var(--mega-menu-banner-width);
                width: 100%;

                &::after,
                .ddtmm-mega-menu-ad {
                    content: '';
                    display: block;
                    width: $ddtmm-mega-menu-banner-width;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: $ddtmm-mega-menu-bg-color;
                }
            }
        }
    }

    .ddtmm-site-nav-toggle {
        height: $ddtmm-drilldown-site-nav-toggle-height;
        padding: 0 $ddtmm-drilldown-site-nav-toggle-padding-x;
        display: flex;

        @include media-breakpoint-down(md) {
            border-color: transparent;
        }

        @include media-breakpoint-up(xxl) {
            display: none;
        }
    }
}