@each $size in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            .content,
            .card-body {
                display: none;
            }

            &.active {
                .collapsed-icon-flip svg {
                    transform: rotate(-180deg)
                }
                
                .content,
                .card-body {
                    display: block;
                }
            }
        }
    }

    .container div.collapsible-#{$size} button.title {
        color: $black;
    }
}
