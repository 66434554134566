.mobile-country-selector {
    &-title {
        color: $mobile-country-selector-li-color;
    }

    &-group li {
        padding: $mobile-country-selector-padding-y $mobile-country-selector-padding-x;
        color: $mobile-country-selector-li-color;
    }
}
