@use 'sass:list';

.btn {
    text-transform: $btn-text-transform;

    > svg,
    span > svg {
        fill: currentcolor;
        width: 20px;
        height: 20px;
    }

    &-sm {
        > svg,
        span > svg {
            width: 12px;
            height: 12px;
        }
    }

    &-block {
        display: block;
        width: 100%;
    }

    &-close {
        &-small {
            width: $btn-close-small-width;
            height: $btn-close-small-height;
        }
    }

    &-reset {
        border: none;
        background: transparent;
    }

    &-outline {
        &-primary {
            border-color: $btn-outline-primary-border-color;
        }

        &-dark {
            border-color: $btn-outline-dark-border-color;
        }
    }

    &-form-control {
        font-weight: $btn-form-control-font-weight;
        min-width: $btn-form-control-min-width;
    }

    &-checkbox:not(.active) {
        .form-check-input {
            transition-duration: .15s;
        }

        &:hover {
            .form-check-input {
                border-color: $btn-checkbox-border-color-hover;
            }
        }
    }

    &-xmas-red {
        &:not(.active) {
            .form-check-input {
                transition-duration: .15s;
            }

            &:hover {
                .form-check-input {
                    border-color: $btn-xmas-red-accent-color;
                }
            }
        }

        .form-check-input {
            border-color: $btn-xmas-red-color;
        }

        &.active {
            .form-check-input {
                background-color: $btn-xmas-red-accent-color;
                border-color: $btn-xmas-red-accent-color;
            }
        }
    }

    &-share {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: $btn-share-bg;
        border: $border-width solid $btn-share-border;
        border-radius: 50%;
        padding: 0;

        > svg {
            fill: $btn-share-color;
            width: 24px;
            height: 24px;
        }
    }
}

@each $color, $value in $btn-custom-colors {
    .btn-#{$color} {
        @include button-variant($value...);
    }
}

@each $color, $value in $btn-custom-colors {
    $first: list.nth($value, 1);

    .btn-outline-#{$color} {
        @include button-outline-variant($first);
    }
}

.amazonpay-wrap > *,
[amazonpay-type="signIn"],
.amazon-express-button {
    min-height: 45px;
}

/* stylelint-disable-next-line selector-id-pattern */
#klarnaSignIn {
    min-height: 48px;
}

.checkout-continue .braitnree-cart-paypal-button {
    min-height: 41px;
}

.disabled-wrapper {
    opacity: 0.5;
    pointer-events: none;
}
