.text {
    &-transparent {
        color: transparent !important;
    }

    &-inherit {
        color: inherit !important;
    }

    &-gray-300 {
        color: $gray-300 !important;
    }

    &-gray-400 {
        color: $gray-400 !important;
    }

    &-gray-500 {
        color: $gray-500 !important;
    }

    &-gray-800 {
        color: $gray-800 !important;
    }

    &-gray-900 {
        color: $gray-900 !important;
    }

    &-transform-none {
        text-transform: none !important;
    }

    &-lg-left {
        @include media-breakpoint-up(lg) {
            text-align: left !important;
        }
    }

    &-right {
        text-align: right !important;
    }

    &-round-brackets {
        &::before {
            content: '(';
            margin-right: -0.125rem;
        }

        &::after {
            content: ')';
            margin-left: -0.125rem;
        }
    }
}

.fw-medium {
    font-weight: $font-weight-medium !important;
}

.x-small {
    font-size: 0.75em !important;
}

.xx-small {
    font-size: 0.625em;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.line-through {
    position: relative;

    &::before {
        z-index: 1;
        position: absolute;
        content: "";
        background: $border-color;
        height: $border-width;
        left: 0;
        top: 50%;
        width: 100%;
    }

    &-over {
        z-index: 2;
        position: relative;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}
