.yotpo {
    .text-m {
        font-size: $font-size-sm!important;
        line-height: normal;
    }

    .standalone-bottomline {
        .star-clickable,
        &.star-clickable {
            align-items: center;
            gap: 2px;

            a {
                color: $link-color !important;

                &:hover {
                    color: $link-hover-color !important;
                }
            }
        }
      
        .yotpo-stars {
            transform: scale(0.8);
            transform-origin: center left;
            margin-right: -15px;
        }
    }

    .write-question-review-buttons-container {
        @include media-breakpoint-only(xs) {
            display: flex;
        }
    
        .write-question-review-button {
            @include media-breakpoint-only(xs) {
                height: auto;
            }
        }
    }

    .yotpo-star-distribution-wrapper {
        float: none !important;
    }

    .yotpo-logo-link-new {
        display: none;
    }
}
