@import "../utilities/swatch";
@import "../variables";

.site-search {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(xxl) {
        width: 89%;
    }

    .search-close,
    .search-button,
    .reset-button {
        position: absolute;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        background-color: transparent;
    }

    .search-close,
    .search-button {
        left: 6px;
    }

    .reset-button {
        right: 6px;
    }

    input {
        padding-left: 2rem;
        height: $header-search-input-height;
    }
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    display: block;
    position: absolute;
    background-color: $white;
    border-radius: $border-radius;
    inset: 0 0 auto;
    z-index: $header-search-suggestions-z-index;
    box-shadow: var(--#{$prefix}box-shadow-lg);

    .header {
        &:not(:first-child) {
            border-top: 1px solid $border-color;
        }
    }

    .items {
        .list {
            padding: 0;
            list-style-type: none;
        }

        .link {
            color: $gray-500;
            text-decoration: none;

            &:hover .name {
                text-decoration: underline;
            }
        }
    }

    .item {
        .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .category-parent {
        color: $gray-400;
        font-size: 0.875em;
    }

    .selected {
        background-color: $gray-200;
    }
}
