.card {
    box-shadow: (var(--#{$prefix}box-shadow));

    &-hover-shadow {
        transition: .15s;

        &:hover {
            @media (hover: $media-rule-hover) {
                transform: translateY(-1px);
                box-shadow: var(--#{$prefix}box-shadow-lg);
            }
        }
    }
}

.card-header + .card-body {
    padding-top: 0;
}

