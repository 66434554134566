.breadcrumb {
    align-items: center;
    direction: ltr;
    display: inline-flex;
    width: max-content;

    &-wrapper {
        direction: rtl;
        display: block;
        margin-left: -$breadcrumb-wrapper-gutter;
        margin-right: -$breadcrumb-wrapper-gutter;
        max-width: fit-content;
        overflow: auto;
        padding-left: $breadcrumb-wrapper-gutter;
        padding-right: $breadcrumb-wrapper-gutter;
    }

    &-item {
        display: flex;
        line-height: 1;

        + .breadcrumb-item {        
            &::before {
                width: 0.25rem;
                height: 0.25rem;
                border-radius: 50%;
                background: $breadcrumb-divider-color;
                padding-right: 0;
                margin-right: $breadcrumb-item-padding-x;
                margin-top: $breadcrumb-divider-margin-top;
            }
        }

        a {
            text-decoration: none;
            color: $breadcrumb-item-color;

            &:hover {
                @media (hover: $media-rule-hover) {
                    text-decoration: underline;
                }
            }
        }

        svg {
            fill: $breadcrumb-item-color;
            width: $breadcrumb-icon-width;
            height: $breadcrumb-icon-height;
        }
    }
}
