.form {
    &-group,
    &-floating {
        margin-bottom: .75rem;

        &.required .form-control-label::before {
            content: "*";
            position: absolute;
            color: inherit;
        }

        > .form-control:disabled,
        > .form-control[readonly],
        > .form-select:disabled,
        > .form-select[readonly] {
            --#{$prefix}border-color: transparent;

            color: $form-disabled-input-color;

            ~ label {
                color: $form-disabled-input-color;

                &::after {
                    --#{$prefix}secondary-bg: #{$form-disabled-background-color};
                }
            }
        }
    }

    &-floating {
        &.required .form-control-label::before {
            top: $input-padding-y;
            right: 0;
        }
    }

    &-group {
        &.required {
            .form-control-label {
                position: relative;

                &::before {
                    top: -5px;
                    right: -10px;
                }
            }
        }
    }

    &-control,
    &-select {
        &:disabled,
        &[readonly] {
            --#{$prefix}secondary-bg: #{$form-disabled-background-color};

            background-color: var(--#{$prefix}secondary-bg);
        }
    }

    &-control {
        &::-webkit-date-and-time-value {
            text-align: left;
        }

        &.is-warning:not(.is-invalid) {
            border-color: $warning-600;
        }
    }

    &-select {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-floating {
        label {
            color: $form-floating-label-color;
        }

        > .form-control:focus ~ label,
        > .form-control:not(:placeholder-shown) ~ label,
        > .form-control-plaintext ~ label,
        > .form-select ~ label {
            color: $form-floating-label-color;

            &::after {
                background-color: transparent;
            }
        }
    }

    &-check {
        &-btn {
            position: relative;
            padding: 0;

            .form-check-input {
                cursor: pointer;
                position: absolute;
                margin: 0;
                top: 1.5rem;
                left: 0.6875rem;
                transform: translateY(-50%);

                &:checked + .form-check-label {
                    background: $form-check-btn-active-bg-color;
                    border-color: $form-check-input-checked-bg-color;
                }
            }

            &:hover {
                .form-check-input:not(:checked) {
                    border-color: $form-check-input-border-color-hover;
                }
            }

            .form-check-label {
                display: block;
                cursor: pointer;
                border-radius: var(--#{$prefix}border-radius);
                border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
                padding: 0.75rem 0.6875rem 0.75rem 2.5rem;

                &:hover {
                    background-color: $form-check-btn-active-bg-color;
                    border-color: $form-check-btn-active-bg-color;
                }
            }
        }
    }
}

.warning-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    font-style: $form-feedback-font-style;
    color: $warning-600;
    
    @include font-size($form-feedback-font-size);

    .is-warning:not(.is-invalid) ~ & {
        display: block;
    }
}

// start overrides for Braintree CC widget
/* stylelint-disable */
#braintreeCardOwner,
#braintreeCardNumber,
#braintreeCvv,
#braintreeExpirationDate {
    min-height: $input-height-lg;
    padding: 0 $input-padding-x-lg;
    border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);

    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &.braintree-hosted-fields-valid {
        border: var(--#{$prefix}border-width) solid var(--#{$prefix}success);
    }

    &.braintree-hosted-fields-invalid {
        border: var(--#{$prefix}border-width) solid var(--#{$prefix}danger);
    }

    &.braintree-hosted-fields-focused {
        box-shadow: $input-focus-box-shadow;
    }
}

.payment-form-fields {
    .payment-method-braintree {
        .error {
            min-height: auto;
            color: var(--#{$prefix}danger);
        }

        .braintree-hosted-fields-iframe-container {
            border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
        }

        .braintree-field-wrap {
            height: $input-height-lg;
        }

        .braintree-hosted-fields-to-display {
            height: $input-height-lg;
            padding: $input-padding-y-lg $input-padding-x-lg;
            line-height: $input-line-height;
            border-radius: $border-radius;
            color: $input-disabled-color;
            background-color: $input-disabled-bg;
            border-color: $input-disabled-border-color;
        }
    }
}
/* stylelint-enable */
// end  overrides for Braintree CC widget