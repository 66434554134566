@mixin toast-message() {
    width: 100%;
    
    &:not(:first-child) {
        margin-top: 4px;
    }
}

@mixin toast-alert() {
    animation: fade 3s linear forwards;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;

    @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
    }

    &.show {
        display: block;
    }
}
