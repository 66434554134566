.modal {
    &-header-center {
        position: relative;
        justify-content: center;

        .btn-close {
            position: absolute;
            right: 1rem;
        }
    }
}

.modal-header + .modal-body {
    padding-top: 0;
}