.clear {
    &-left {
        clear: left !important;
    }
}

.overflow-hidden {
    @include media-breakpoint-down(xl) {
        &--max-lg {
            overflow: hidden !important;
        }
    }
}

.collapsed.collapsed-icon-flip svg {
    transform: rotate(-180deg);
}

.svg-wrapper-rotate-0 svg {
    transform: rotate(0) !important;
}

.flex {
    &-1 {
        flex: 1 !important;
    }

    &-basis-0 {
        flex-basis: 0;
    }
}

.h {
    &-min {
        &-43p {
            min-height: 43px !important;
        }
    }

    &-max {
        &-75p {
            max-height: 75px !important;
        }

        &-200p {
            max-height: 200px !important;
        }
    }
}

.w {
    &-70p {
        width: 70px !important;
    }

    &-80p {
        width: 80px !important;
    }

    &-100p {
        width: 100px !important;
    }

    &-min {
        &-0 {
            min-width: 0 !important;
        }
    }

    &-max {
        &-65p {
            max-width: 65px !important;
        }

        &-250p {
            max-width: 250px !important;
        }
    }

    &-xxxl {
        &-auto {
            @include media-breakpoint-up(xxxl) {
                width: auto !important;
            }
        }
    }
}

.p {
    &x-container {
        padding-left: calc(0.5 * var(--#{$prefix}gutter-x)) !important;
        padding-right: calc(0.5 * var(--#{$prefix}gutter-x)) !important;
    }
}

.mx {
    &-n-container {
        margin-left: calc(-0.5 * var(--#{$prefix}gutter-x)) !important;
        margin-right: calc(-0.5 * var(--#{$prefix}gutter-x)) !important;
    }
}

.left {
    &-0 {
        left: 0 !important;
    }
}

.right {
    &-0 {
        right: 0 !important;
    }
}

.bottom {
    &-1p {
        bottom: 1px;
    }
}

.col {
    @include media-breakpoint-up(md) {
        &-md-220p {
            flex: 0 0 auto;
            width: 220px;
        }
    }
}

.z-index {
    &-5 {
        z-index: 5 !important;
    }
}

.img-wrapper-ratio-1x1 {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 100%;

    >img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.object {
    &-fit-contain {
        object-fit: contain !important;
    }

    &-position-center {
        object-fit: center !important;
    }
}

.sr-only {
    white-space: nowrap;
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.d-none-custom-payments {
    display: none !important;
}